import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import './app.scss';
import logo from "./assets/career-big-logo.png";
import {FaTimes} from 'react-icons/fa';
import JobView from "./modals/JobView";
import {Pagination} from '@mui/material';
import {useEffect, useState} from "react";
import {apiUrl} from "./config";

function App() {
    const [page, setPage] = useState(1);
    const [data, setData] = useState(null);

    const pagination = (e) => {
        setPage(e.target.textContent);
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(apiUrl + "allJobs/flter?page=" + page, requestOptions)
            .then(response => response.json())
            .then(result => setData(result))
            .catch(error => console.log('error', error));
    }, [page])
    return (
        <div className="app">
            <Header/>
            <div className="main container text-center">
                <div className="contents">
                    <img src={logo} alt="" className="img-fluid logo"/>
                    <p className="mb-0 mt-3 text-muted">by Jobsicle</p>
                    <span className="tag d-inline-block mt-4 small">ALL OPEN JOB OFFERS</span>
                </div>
                <div className="job-list my-3">
                    <div className="row headerItems d-none d-lg-flex pb-1 pt-1 rounded">
                        <div className="col-md-5"/>
                        <div className="col-md-7 headerCol">
                            <div className="colItem">Salary Range</div>
                            <div className="colItem">Type</div>
                            <div className="colItem">Location</div>
                            <div className="colItem">Deadline</div>
                            <div className="colItem"><FaTimes/></div>
                        </div>
                    </div>
                    {data !== null ?
                        <>
                            {data.jobs.data.map((item, index) =>
                                <div key={index}>
                                    <JobView item={item}/>
                                </div>
                            )}
                            <div className="py-3 text-center">
                                <Pagination
                                    count={Math.ceil(data.jobs.total / 20)}
                                    onClick={(e) => pagination(e)}
                                    hidePrevButton hideNextButton
                                    color="primary"
                                    className="d-flex justify-content-center"/>
                            </div>
                        </> : ""
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
