import React, {useEffect, useState} from 'react';
import {Modal, Box} from "@mui/material";
import {FaTimes} from 'react-icons/fa';
import Moment from "react-moment";
import "../app.scss";
import {AiFillCheckCircle} from 'react-icons/ai';
import {apiUrl, linkDirect} from "../config";
import AttachmentViewer from "../AttachmentViewer";

const JobView = ({item}) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const openJob = (id) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(apiUrl + "jobView/" + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
                setOpen(true)
            })
            .catch(error => console.log('error', error));
    }
    return (
        <>
            <div className="row contentItems py-2 rounded" onClick={() => openJob(item.id)}>
                <div className="col-lg-5">
                    <div className="row mx-0">
                        <div className="col-lg-4 text-sm-left text-right">
                            <div className="border-main">Job</div>
                            <div className="time text-muted"><Moment fromNow>{item.created_at}</Moment></div>
                        </div>
                        <div className="col-lg-8 text-left">
                            <div className="heading text-muted">{item.title}</div>
                            <div className="company fst-italic d-flex text-black-50">
                                <span className="w-fit">{item.name}</span> {parseInt(item.is_verified) === 1 &&
                            <AiFillCheckCircle className="icon-size ml-1 text-pr"/>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 contentCol text-muted">
                    <div className="mobile text-left ms-3 d-lg-none">
                        <div className="text-left">{item.salary_range}</div>
                        <div className="">{item.type}</div>
                        <div className="">{item.location}</div>
                        <div className=""><Moment format="DD MMM, YYYY">{item.created_at}</Moment></div>
                        <div className=""/>
                    </div>
                    <div className="desktop d-none d-lg-block">
                        <div className="colItem mt-3 text-left">{item.salary_range}</div>
                        <div className="colItem mt-3">{item.type}</div>
                        <div className="colItem mt-3">{item.location}</div>
                        <div className="colItem mt-3"><Moment format="DD MMM, YYYY">{item.created_at}</Moment></div>
                        <div className="colItem mt-3"/>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition className="jobModal"
                BackdropProps={{
                    timeout: 500,
                }}>
                <Box className="jobViewContent">
                    <div className="header text-right py-2">
                        <span className="icon" onClick={() => setOpen(false)}><FaTimes/></span>
                    </div>
                    <div className="fullContent text-muted">
                        {data !== null ?
                            <>
                                <div className="topBar mb-3">
                                    <h4 className="title my-0">{data.title}</h4>
                                    <div className="d-block d-md-inline">
                                        <span className="mx-md-2 border-main">{data.type}</span>
                                    </div>
                                    <div className="time"><Moment
                                        fromNow>{data.created_at}</Moment></div>
                                    <div className="company">{data.company_name}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mt-2">
                                        <div className="content rounded-sm border text-sm text-muted">
                                            {<div style={{whiteSpace: 'pre-line'}}
                                                  dangerouslySetInnerHTML={{
                                                      __html: data.description
                                                  }}/>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-2 text-sm">
                                        <div className="p-3 border rounded-sm">
                                            <p className="mb-2"><b>Salary Range: </b> {data.salary_range}
                                            </p>
                                            <p className="mb-2"><b>Job Type: </b> {data.type}
                                            </p>
                                            <p className="mb-2">
                                                <b>Location: </b> {data.location}
                                            </p>
                                            <p className="mb-2">
                                                <b>Qualication: </b>
                                                {data.qualification}
                                            </p>
                                            <p className="mb-2"><b>Experience: </b>
                                                {data.experience}
                                            </p>
                                            <div className="mb-2"><b>Required items: </b>
                                                <ul className="ms-2 mt-2">
                                                    {data.required_items !== undefined && data.required_items !== null ?
                                                        JSON.parse(data.required_items).map((item, index) =>
                                                            item !== null &&
                                                            <li key={index}>{item}</li>
                                                        ) : ""
                                                    }
                                                </ul>
                                            </div>
                                            <p className="mb-2"><b>Ref No: </b>
                                                {data.ref_no}
                                            </p>
                                            <p className="mb-2"><b>Sector: </b>
                                                {data.sector}
                                            </p>
                                            <p className="mb-2"><b>Closing On: </b>
                                                <Moment
                                                    format="DD MMM, YYYY">{data.expired_at}</Moment>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ul className="ps-4 py-3 small">
                                    {data.application_form !== null && data.application_form.length > 0 ?
                                        <li>
                                            This Job contains an attachment <a href={data.application_form}
                                                                               target="_blank"
                                                                               className="btn btn-outline-main ml-md-3 d-inline-block">Open</a>
                                        </li>
                                        : ""}
                                    <li className="mt-2">
                                        Open this job on Jobsicle to apply <a href={linkDirect + data.id}
                                                                              target="_blank"
                                                                              className="btn btn-outline-main ml-md-3 d-inline-block">Open</a>
                                    </li>
                                </ul>
                            </>
                            : ""
                        }
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default JobView;