import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/career-small-logo.png";

const Footer = () => {
    return (
        <div className="footer pt-5 mt-2">
            <div className="bg-pr text-white d-flex justify-content-center py-2 text-center text-uppercase">
                <p className="mb-0 mt-2">want to submit a listing</p>
                <a target="_blank" className="sbmt_btn ms-2" href="https://jobsicle.mv">Get Registered on Jobsicle</a>
            </div>
            <div className="container d-flex py-3">
                <div className="items w-50">
                    <img src={logo} alt="" className="img-fluid footer-logo"/>
                    <p className="my-0">Powered by Jobsicle</p>
                </div>
                <div className="items w-50 text-right">
                    <p className="my-0">+960 7379494 <span className="text-pr">●</span> mail@jobsicle.mv</p>
                    <p className="my-0">Copyrights 2022, JOBSICLE</p>
                </div>
            </div>
            <hr className="my-0"/>
            <div className="py-3 text-center">
                <a href="" className="mx-1">CAREER.MV</a> | <a href="" className="mx-1">JOBSICLE.MV</a> | <a href=""
                                                                                                             className="mx-1">PUBLIC.MV</a>
            </div>
        </div>
    );
};

export default Footer;