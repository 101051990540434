import React from 'react';
import logo from "../assets/career-small-logo.png";
import "./layout.scss";

const Header = () => {
    return (
        <div className="fixed-top header">
            <div className="logo"><img src={logo} className="img-fluid" alt=""/></div>
        </div>
    );
};

export default Header;